import React, { useEffect, useState } from "react";
import "./Home.css";
// import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import LatestMovies from "./HomeComponents/LatestMovies";
import OriginalMovies from "./HomeComponents/OriginalMovies";
import ShortFilms from "./HomeComponents/ShortFilms";
import WebSeries from "./HomeComponents/WebSeries";
import HomeBanner from "./HomeComponents/HomeBanner";
import instance from "./common/Api";
import { Button, Spinner, Modal, ModalHeader } from "react-bootstrap";
import PWAInstallerPrompt from "react-pwa-installer-prompt";
import CookieConsent from "react-cookie-consent";
import { RiArrowRightSLine, RiDownload2Fill } from "react-icons/ri";
import Loader from "./common/Loader";
// import "react-cookie-consent/dist/cookie-consent.css";

const Home = (props) => {
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [data, setData] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [showModelForInstall, setshowModelForInstall] = useState(false);
  const [show, setShow] = useState(false);
  const [FCM_Sent, setFCM_Sent] = useState(true);
  const [newToken, setNewToken] = useState("");
  const [dataMobileSection_1, setDataMobileSection_1] = useState(null);

  const [mobileOriginals, setMobileOriginals] = useState(null);
  const [mobileShortFilm, setMobileShortFilm] = useState(null);
  const [mobileWebSeries, setMobileWebSries] = useState(null);

  const [dataMobileSection_2, setDataMobileSection_2] = useState(null);
  const handleClose = () => {
    setshowModelForInstall(false);
  };
  // const handleShow = () => setShow(true);

  useEffect(() => {
    // const notificationInteracted = localStorage.getItem("pwaInteracted0");

    // If the notification hasn't been interacted with and 10 seconds have passed, show it
    const timeoutId = setTimeout(() => {
      setshowModelForInstall(true);
    }, 3000);

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Run this effect only once on initial mount

  useEffect(() => {
    const fetchWebDataWithDelay = setTimeout(() => {
      instance
        .get(`/contentBycatAndPopular?ip=${props.ip}`)
        .then((response) => {
          console.log(response.data["0"].mediaContentByCategory, "RESPOSE:-");
          setData(response.data["0"].mediaContentByCategory);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
      // if (!isMobile) {
      //   instance
      //     .get(`/contentBycatAndPopular?ip=${props.ip}`)
      //     .then((response) => {
      //       console.log(response.data["0"].mediaContentByCategory, "RES");
      //       setData(response.data["0"].mediaContentByCategory);
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching data:", error);
      //     })
      //     .finally(() => {
      //       setLoading(false);
      //     });
      // } else {
      //   instance
      //     .post(`/home_first_section?id=1&ip=${props.ip}`)
      //     .then((response) => {
      //       console.log(response.data.data[1].data);
      //       setDataMobileSection_1(response.data.data[1].data);
      //       // setData(response.data);
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching data:", error);
      //     })
      //     .finally(() => {
      //       MobileSecondSection();
      //       // setLoading(false);
      //     });
      // }
    }, 1000);
    //
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(fetchWebDataWithDelay);
    };
    // return () => clearTimeout(fetchWebDataWithDelay);
  }, [isMobile]);

  const MobileSecondSection = () => {
    instance
      .post(`/home_second_section?id=1&ip=${props.ip}`)
      .then((response) => {
        // console.log(response.data.data, "setDataMobileSection_2");
        if (response.data.data[0].title === "Latest") {
          // console.log(response.data.data[0].data, "setMobileOriginals");
          setMobileOriginals(response.data.data[0].data);
        }
        if (response.data.data[2].title === "Short Film") {
          setMobileShortFilm(response.data.data[2].data);
        }
        if (response.data.data[1].title === "Web Series") {
          setMobileWebSries(response.data.data[1].data);
        }
        setDataMobileSection_2(response.data.data[1].data);
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    instance
      .get(`/home_sliders_web?ip=${props.ip}`)
      .then((response) => {
        setBannerData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch is complete
      });
  }, []);

  // const handleNotificationInteraction = () => {
  //   // Set a flag in local storage indicating that the notification has been interacted with
  //   localStorage.setItem("pwaInteracted0", "true");
  //   setshowModelForInstall(false);
  // };
  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )}
      <div
        style={
          props.isMobile
            ? {
                width: "100%",
                minHeight: "55rem",
                // position: "relative",
                backgroundImage: `url(./main-bg.jpg)`,
                backgroundSize: "auto",
                // backgroundPosition: "center",
                // backgroundColor: "#141414",
                backgroundRepeat: "repeat-y",
                backgroundBlendMode: "overlay",
              }
            : {
                backgroundImage: `url(./main-bg-landscap.jpg)`,
                backgroundSize: "auto",
                // backgroundPosition: "center",
                // backgroundColor: "#141414",
                backgroundRepeat: "repeat-y",
                backgroundBlendMode: "overlay",
              }
        }
      >
        <PWAInstallerPrompt
          render={({ onClick }) => (
            <Modal show={showModelForInstall} onHide={handleClose}>
              <ModalHeader
                style={{
                  padding: "0",
                  backgroundColor: "white",
                  borderBottom: "none",
                }}
              >
                <Button
                  variant="secondary"
                  style={{
                    color: "white",
                    backgroundColor: "#007cc2",
                    width: "30px",
                    height: "30px",
                    padding: "0px",
                    marginLeft: "auto",
                    marginRight: "10px",
                    marginBottom: "-40px",
                    zIndex: "9999",
                  }}
                  onClick={handleClose}
                >
                  x
                </Button>
              </ModalHeader>
              <Modal.Body
                style={{
                  backgroundImage: `url(./square_bg.jpg)`,
                  backgroundSize: "cover",
                  color: "white",
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  borderRadius: "10px",
                  // height: "15rem", // Adjust as needed
                  backgroundColor: "black",
                }}
              >
                <RiDownload2Fill
                  className="moving"
                  style={{ height: "50px", fontSize: "28px", color: "#007cc2" }}
                />
                <h6 style={{ color: "#007cc2" }}>
                  Install Ubeetu on your device!
                </h6>

                <Button
                  variant="secondary"
                  style={{
                    color: "white",
                    background: "#008dc4",
                  }}
                  onClick={onClick}
                >
                  INSTALL
                </Button>
              </Modal.Body>
            </Modal>
          )}
          callback={(data) => console.log(data)}
        />

        {bannerData && (
          <HomeBanner
            banner={bannerData}
            style={{ backgroundColor: "#141414" }}
            isMobile={isMobile}
          />
        )}

        {
          isMobile
            ? dataMobileSection_1 && (
                <LatestMovies data={dataMobileSection_1} isMobile={isMobile} />
              )
            : null
          // data &&
          //   data["0"] && (
          //     <LatestMovies
          //       data={data["0"].mediaContentByCategory}
          //       isMobile={isMobile}
          //     />
          //   )
        }

        {isMobile ? (
          <div style={{ backgroundColor: "transparent" }}>
            {data && data[0] && (
              <OriginalMovies
                data={data[0].mediaContentList}
                isMobile={isMobile}
              />
            )}
          </div>
        ) : (
          <div style={{ backgroundColor: "transparent" }}>
            {data && data[0] && (
              <OriginalMovies data={data[0]} isMobile={isMobile} />
            )}
          </div>
        )}

        {isMobile
          ? data &&
            data[2] && (
              <ShortFilms data={data[2].mediaContentList} isMobile={isMobile} />
            )
          : data &&
            data[2] && <ShortFilms data={data[2]} isMobile={isMobile} />}

        {isMobile ? (
          <div
            style={{
              marginBottom: "0",
              paddingBottom: "3rem",
            }}
          >
            {data && data[1] && (
              <WebSeries data={data[1].mediaContentList} isMobile={isMobile} />
            )}
          </div>
        ) : (
          <div
            style={{
              marginBottom: "0",
              paddingBottom: "1rem",
            }}
          >
            {data && data[1] && (
              <WebSeries data={data[1]} isMobile={isMobile} />
            )}
          </div>
        )}
      </div>
      {isMobile ? (
        <CookieConsent
          location="bottom"
          buttonText="OK!"
          cookieName="myCookieConsent"
          style={{
            background: "rgb(235 67 40 / 90%)",
            zIndex: "1999",
            marginBottom: "50px",
            flexWrap: "none",
          }}
          buttonStyle={{
            background: "#008dc4",
            color: "white",
            fontSize: "16px",
            borderRadius: "8px",
          }}
          expires={150}
        >
          This website uses cookies.
          <a
            href="/CookiePolicy"
            style={{ color: "black", textDecoration: "underline" }}
          >
            Learn More
          </a>
        </CookieConsent>
      ) : (
        <CookieConsent
          location="bottom"
          buttonText="OK!"
          cookieName="myCookieConsent"
          style={{
            background: "rgb(235 67 40 / 90%)",
            zIndex: "1999",
            bottom: "0px",
          }}
          buttonStyle={{
            background: "#008dc4",
            color: "white",
            fontSize: "16px",
            borderRadius: "8px",
            width: "120px",
          }}
          expires={150}
        >
          This website uses cookies to ensure you get the best experience on our
          website.
          <a
            href="/CookiePolicy"
            style={{ color: "#008dc4", textDecoration: "underline" }}
          >
            Learn More
          </a>
        </CookieConsent>
      )}
    </>
  );
};

export default Home;
